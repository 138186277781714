import { useState } from 'react';
import { Brain, Menu, X } from 'lucide-react';
import { Link } from 'react-router-dom';

export const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <header className="fixed w-full z-50">
      <nav className="container mx-auto px-4 py-4 md:px-6 md:py-6">
        <div className="flex items-center justify-between">
          <Link to="/" className="flex items-center gap-2">
            <Brain className="h-6 w-6 md:h-8 md:w-8 text-white" />
            <span className="text-xl md:text-2xl font-light text-white">WebKraft</span>
          </Link>
          
          <div className="hidden md:flex items-center gap-8">
            <Link to="/" className="text-sm text-white/80 hover:text-white transition-colors">
              Home
            </Link>
            <Link to="/why" className="text-sm text-white/80 hover:text-white transition-colors">
              Why Digital
            </Link>
            <Link to="/about" className="text-sm text-white/80 hover:text-white transition-colors">
              About Us
            </Link>
            <Link to="/pricing" className="text-sm text-white/80 hover:text-white transition-colors">
              Pricing
            </Link>
            <Link to="/contact" className="btn-primary">
              Contact Us
            </Link>
          </div>

          <button 
            className="md:hidden text-white p-2 bg-white/10 backdrop-blur-sm rounded-lg border border-white/10 shadow-[0_4px_12px_rgba(0,0,0,0.1)]" 
            onClick={() => setIsOpen(!isOpen)}
          >
            {isOpen ? <X className="h-5 w-5" /> : <Menu className="h-5 w-5" />}
          </button>
        </div>

        {isOpen && (
          <div className="absolute left-0 right-0 top-[72px] p-4 bg-black/95 backdrop-blur-lg md:hidden">
            <div className="flex flex-col gap-4 container mx-auto">
              <Link 
                to="/" 
                className="text-lg text-white/80 hover:text-white transition-colors p-2"
                onClick={() => setIsOpen(false)}
              >
                Home
              </Link>
              <Link 
                to="/why" 
                className="text-lg text-white/80 hover:text-white transition-colors p-2"
                onClick={() => setIsOpen(false)}
              >
                Why Digital
              </Link>
              <Link 
                to="/about" 
                className="text-lg text-white/80 hover:text-white transition-colors p-2"
                onClick={() => setIsOpen(false)}
              >
                About Us
              </Link>
              <Link 
                to="/pricing" 
                className="text-lg text-white/80 hover:text-white transition-colors p-2"
                onClick={() => setIsOpen(false)}
              >
                Pricing
              </Link>
              <Link
                to="/contact"
                className="btn-primary text-center"
                onClick={() => setIsOpen(false)}
              >
                Contact Us
              </Link>
            </div>
          </div>
        )}
      </nav>
    </header>
  );
};