import { useState } from 'react';
import { Send } from 'lucide-react';
import OpenAI from 'openai';

const openai = new OpenAI({
  apiKey: import.meta.env.VITE_OPENAI_API_KEY || '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',
  dangerouslyAllowBrowser: true
});

export const ChatBox = () => {
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState<{ text: string; sender: 'user' | 'bot' }[]>([
    {
      text: 'Hi! Tell me about your business and the challenges you face. I can suggest digital solutions to improve your efficiency and online presence.',
      sender: 'bot'
    }
  ]);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!message.trim() || isLoading) return;

    const userMessage = message.trim();
    setMessage('');
    
    setMessages(prev => [...prev, { text: userMessage, sender: 'user' }]);
    
    setIsLoading(true);

    try {
      const completion = await openai.chat.completions.create({
        messages: [
          {
            role: 'system',
            content: 'You are a digital solutions expert helping businesses improve their online presence and operations. Answer in English and be helpful and professional. Focus on practical solutions like websites, business systems, and digital marketing. Suggest AI integration only where it adds genuine value. Give specific examples based on the company\'s industry and challenges.'
          },
          ...messages.map(msg => ({
            role: msg.sender === 'user' ? 'user' as const : 'assistant' as const,
            content: msg.text
          })),
          { role: 'user' as const, content: userMessage }
        ],
        model: 'gpt-3.5-turbo',
      });

      const botResponse = completion.choices[0]?.message?.content;
      if (botResponse) {
        setMessages(prev => [...prev, { text: botResponse, sender: 'bot' }]);
      }
    } catch (error) {
      console.error('OpenAI API Error:', error);
      setMessages(prev => [...prev, {
        text: 'Sorry, I couldn\'t process your request right now. Please try again later.',
        sender: 'bot'
      }]);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="bg-white/10 backdrop-blur-sm rounded-2xl w-full max-w-md mx-auto shadow-[0_8px_32px_rgba(0,0,0,0.2)] border border-white/10">
      <div className="p-3 md:p-4 border-b border-white/10">
        <h3 className="font-light text-white text-sm md:text-base">Discover your digital potential</h3>
      </div>
      
      <div className="p-3 md:p-4 h-[250px] md:h-[300px] overflow-y-auto space-y-3 md:space-y-4">
        {messages.map((msg, index) => (
          <div
            key={index}
            className={`flex ${msg.sender === 'user' ? 'justify-end' : 'justify-start'}`}
          >
            <div
              className={`max-w-[85%] p-2.5 md:p-3 rounded-lg text-left text-sm md:text-base shadow-[0_4px_12px_rgba(0,0,0,0.1)] ${
                msg.sender === 'user'
                  ? 'bg-white/20 text-white'
                  : 'bg-white/10 text-white'
              }`}
            >
              {msg.text}
            </div>
          </div>
        ))}
        {isLoading && (
          <div className="flex justify-start">
            <div className="bg-white/10 text-white p-2.5 md:p-3 rounded-lg text-left text-sm md:text-base shadow-[0_4px_12px_rgba(0,0,0,0.1)]">
              Analyzing possibilities...
            </div>
          </div>
        )}
      </div>

      <form onSubmit={handleSubmit} className="border-t border-white/10 p-3 md:p-4">
        <div className="flex gap-2">
          <input
            type="text"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Describe your business and challenges..."
            className="flex-1 px-3 md:px-4 py-2 bg-white/10 text-white text-sm md:text-base rounded-lg focus:ring-2 focus:ring-white/20 focus:outline-none placeholder-white/50 shadow-[0_4px_12px_rgba(0,0,0,0.1)]"
            disabled={isLoading}
          />
          <button
            type="submit"
            className="bg-white/10 text-white p-2 rounded-lg hover:bg-white/20 transition-colors disabled:opacity-50 disabled:cursor-not-allowed shadow-[0_4px_12px_rgba(0,0,0,0.1)]"
            disabled={isLoading}
          >
            <Send className="h-4 w-4 md:h-5 md:w-5" />
          </button>
        </div>
      </form>
    </div>
  );
};