import { Navbar } from '../components/Navbar';
import { Footer } from '../components/Footer';
import { Link } from 'react-router-dom';
import { ArrowRight } from 'lucide-react';
import { ChatBox } from '../components/ChatBox';

export const HomePage = () => {
  return (
    <div className="min-h-screen bg-gradient-to-br from-rose-500 via-purple-600 to-blue-700">
      <Navbar />
      <main>
        <section className="min-h-screen relative overflow-hidden">
          <div className="container mx-auto px-4 md:px-6 pt-24 md:pt-32 pb-12 md:pb-20">
            <div className="grid md:grid-cols-2 gap-8 md:gap-12 items-center">
              <div className="space-y-6 md:space-y-8 text-center md:text-left">
                <div className="inline-block px-4 py-2 bg-white/10 backdrop-blur-sm rounded-full text-white/90 text-sm shadow-[0_0_15px_rgba(255,255,255,0.1)]">
                  Digital Transformation
                </div>
                
                <h1 className="text-3xl sm:text-4xl md:text-6xl lg:text-7xl xl:text-8xl font-light text-white space-y-1 md:space-y-2">
                  <span className="block">Elevate your</span>
                  <span className="block font-normal bg-clip-text text-transparent bg-gradient-to-r from-white via-white to-white/50">digital presence</span>
                </h1>

                <p className="text-base md:text-lg text-white/80 max-w-md mx-auto md:mx-0">
                  Custom digital solutions that transform your business with modern technology and 
                  smart AI integration where it matters most.
                </p>

                <div className="flex flex-wrap gap-4 justify-center md:justify-start">
                  <Link to="/contact" className="btn-primary w-full sm:w-auto">
                    Try us for free
                    <ArrowRight className="h-5 w-5 group-hover:translate-x-1 transition-transform" />
                  </Link>
                </div>
              </div>

              <div className="relative mt-8 md:mt-0">
                <ChatBox />
              </div>
            </div>

            <div className="mt-16 md:mt-32 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 md:gap-8 text-white/80">
              <div className="p-4 md:p-6 backdrop-blur-sm bg-white/5 rounded-2xl border border-white/10 shadow-[0_8px_32px_rgba(0,0,0,0.2)] hover:shadow-[0_8px_32px_rgba(255,255,255,0.1)] transition-all duration-300">
                <h3 className="text-lg md:text-xl font-light mb-3 md:mb-4">Web Development</h3>
                <p className="text-sm opacity-80">
                  Custom websites and web applications designed to showcase your brand and engage customers.
                </p>
              </div>
              <div className="p-4 md:p-6 backdrop-blur-sm bg-white/5 rounded-2xl border border-white/10 shadow-[0_8px_32px_rgba(0,0,0,0.2)] hover:shadow-[0_8px_32px_rgba(255,255,255,0.1)] transition-all duration-300">
                <h3 className="text-lg md:text-xl font-light mb-3 md:mb-4">Digital Strategy</h3>
                <p className="text-sm opacity-80">
                  Comprehensive digital transformation strategy with smart AI integration where beneficial.
                </p>
              </div>
              <div className="p-4 md:p-6 backdrop-blur-sm bg-white/5 rounded-2xl border border-white/10 shadow-[0_8px_32px_rgba(0,0,0,0.2)] hover:shadow-[0_8px_32px_rgba(255,255,255,0.1)] transition-all duration-300">
                <h3 className="text-lg md:text-xl font-light mb-3 md:mb-4">Business Systems</h3>
                <p className="text-sm opacity-80">
                  Custom business applications that automate processes and improve efficiency.
                </p>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};