import { Navbar } from '../components/Navbar';
import { Footer } from '../components/Footer';
import { Code2, MapPin, Trophy, Users } from 'lucide-react';

const features = [
  {
    icon: MapPin,
    title: 'Locally Based',
    description: 'Based in the technology capital Trondheim'
  },
  {
    icon: Code2,
    title: 'Technical Expertise',
    description: 'Specialists in modern AI technology'
  },
  {
    icon: Users,
    title: 'Dedicated Team',
    description: 'Experienced developers and consultants'
  },
  {
    icon: Trophy,
    title: 'Customer Focus',
    description: 'Your success is our priority'
  }
];

export const AboutPage = () => {
  return (
    <div className="min-h-screen bg-gradient-to-br from-rose-500 via-purple-600 to-blue-700">
      <Navbar />
      <main className="pt-32 pb-20">
        <div className="container mx-auto px-6">
          <div className="max-w-4xl mx-auto">
            <div className="text-center mb-16">
              <div className="inline-block px-4 py-2 bg-white/10 backdrop-blur-sm rounded-full text-white/90 text-sm mb-6">
                About Us
              </div>
              <h1 className="text-6xl font-light text-white mb-6">
                Our Story
              </h1>
              <p className="text-lg text-white/80">
                We are a team of passionate technologists dedicated to helping businesses 
                harness the power of artificial intelligence.
              </p>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-16">
              {features.map((feature, index) => {
                const Icon = feature.icon;
                return (
                  <div key={index} className="bg-white/10 backdrop-blur-sm p-6 rounded-2xl border border-white/10 shadow-[0_8px_32px_rgba(0,0,0,0.2)] hover:shadow-[0_8px_32px_rgba(255,255,255,0.1)] transition-all duration-300">
                    <div className="flex items-center gap-4 mb-4">
                      <div className="p-3 bg-white/5 rounded-lg">
                        <Icon className="h-6 w-6 text-white" />
                      </div>
                      <h3 className="text-xl font-light text-white">{feature.title}</h3>
                    </div>
                    <p className="text-white/80">{feature.description}</p>
                  </div>
                );
              })}
            </div>

            <div className="bg-white/10 backdrop-blur-sm rounded-2xl p-8 border border-white/10 shadow-[0_8px_32px_rgba(0,0,0,0.2)]">
              <h2 className="text-2xl font-light text-white mb-6">Company Information</h2>
              <div className="space-y-4 text-white/80">
                <p><strong className="text-white">Rekdal Salg og Service</strong></p>
                <p>Organization Number: 933 294 684</p>
                <p>Address: Hoemsbakken 1, 7023 Trondheim</p>
                <p>Phone: +47 959 16 988</p>
                <p>Email: rekdalkontak@gmail.com</p>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};