import { Navbar } from '../components/Navbar';
import { Footer } from '../components/Footer';

export const CookiesPage = () => {
  return (
    <div className="min-h-screen bg-gradient-to-br from-rose-500 via-purple-600 to-blue-700">
      <Navbar />
      <main className="pt-32 pb-20">
        <div className="container mx-auto px-6">
          <div className="max-w-3xl mx-auto">
            <div className="text-center mb-16">
              <div className="inline-block px-4 py-2 bg-white/10 backdrop-blur-sm rounded-full text-white/90 text-sm mb-6">
                Cookies
              </div>
              <h1 className="text-6xl font-light text-white mb-6">
                Cookie Policy
              </h1>
              <p className="text-lg text-white/80">
                How we use cookies to improve your experience
              </p>
            </div>
            
            <div className="space-y-8">
              <div className="bg-white/10 backdrop-blur-sm rounded-2xl p-8">
                <h2 className="text-2xl font-light text-white mb-4">1. What Are Cookies?</h2>
                <p className="text-white/80">
                  Cookies are small text files that are stored on your device when you 
                  visit our website. They are important for providing you with a good user experience and 
                  help us understand how the website is used.
                </p>
              </div>

              <div className="bg-white/10 backdrop-blur-sm rounded-2xl p-8">
                <h2 className="text-2xl font-light text-white mb-4">2. Our Cookies</h2>
                <div className="space-y-4">
                  <div>
                    <h3 className="text-lg font-light text-white mb-2">Necessary Cookies</h3>
                    <ul className="list-disc pl-6 text-white/80 space-y-1">
                      <li>Session cookies</li>
                      <li>Security cookies</li>
                      <li>Functionality cookies</li>
                    </ul>
                  </div>
                  
                  <div>
                    <h3 className="text-lg font-light text-white mb-2">Analytical Cookies</h3>
                    <ul className="list-disc pl-6 text-white/80 space-y-1">
                      <li>Google Analytics</li>
                      <li>User behavior</li>
                      <li>Performance measurement</li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="bg-white/10 backdrop-blur-sm rounded-2xl p-8">
                <h2 className="text-2xl font-light text-white mb-4">3. Your Choices</h2>
                <p className="text-white/80 mb-4">
                  You have full control over cookies on your device:
                </p>
                <ul className="list-disc pl-6 text-white/80 space-y-2">
                  <li>Manage via browser settings</li>
                  <li>Choose cookies individually</li>
                  <li>Use third-party tools</li>
                </ul>
              </div>

              <div className="bg-white/10 backdrop-blur-sm rounded-2xl p-8">
                <h2 className="text-2xl font-light text-white mb-4">4. Contact Us</h2>
                <div className="text-white/80">
                  <p className="mb-4">For questions about cookies, contact:</p>
                  <div className="space-y-2">
                    <p>WebKraft Norge</p>
                    <p>Email: rekdalkontak@gmail.com</p>
                    <p>Phone: +47 959 16 988</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};