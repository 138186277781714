import { Navbar } from '../components/Navbar';
import { Footer } from '../components/Footer';

export const PrivacyPage = () => {
  return (
    <div className="min-h-screen bg-gradient-to-br from-rose-500 via-purple-600 to-blue-700">
      <Navbar />
      <main className="pt-32 pb-20">
        <div className="container mx-auto px-6">
          <div className="max-w-3xl mx-auto">
            <div className="text-center mb-16">
              <div className="inline-block px-4 py-2 bg-white/10 backdrop-blur-sm rounded-full text-white/90 text-sm mb-6">
                Privacy
              </div>
              <h1 className="text-6xl font-light text-white mb-6">
                Privacy Policy
              </h1>
              <p className="text-lg text-white/80">
                Your security and privacy are our highest priority
              </p>
            </div>
            
            <div className="space-y-8">
              {/* Sections with new styling */}
              <div className="bg-white/10 backdrop-blur-sm rounded-2xl p-8">
                <h2 className="text-2xl font-light text-white mb-4">1. Information Collection</h2>
                <p className="text-white/80 mb-4">
                  WebKraft Norge is committed to protecting your personal information. We collect the following 
                  information when you use our services:
                </p>
                <ul className="list-disc pl-6 text-white/80 space-y-2">
                  <li>Name and contact information</li>
                  <li>Email address</li>
                  <li>Phone number</li>
                  <li>Business information</li>
                  <li>IP address and browser information</li>
                  <li>Payment information</li>
                </ul>
              </div>

              <div className="bg-white/10 backdrop-blur-sm rounded-2xl p-8">
                <h2 className="text-2xl font-light text-white mb-4">2. Use of Information</h2>
                <p className="text-white/80 mb-4">
                  We use collected information for the following purposes:
                </p>
                <ul className="list-disc pl-6 text-white/80 space-y-2">
                  <li>Delivery and customization of services</li>
                  <li>Billing and accounting</li>
                  <li>Communication about services</li>
                  <li>Customer service and support</li>
                  <li>Service improvement</li>
                  <li>Marketing (with consent)</li>
                </ul>
              </div>

              <div className="bg-white/10 backdrop-blur-sm rounded-2xl p-8">
                <h2 className="text-2xl font-light text-white mb-4">3. Your Rights</h2>
                <p className="text-white/80 mb-4">
                  Under GDPR, you have the following rights:
                </p>
                <ul className="list-disc pl-6 text-white/80 space-y-2">
                  <li>Right to access your data</li>
                  <li>Right to correct data</li>
                  <li>Right to delete data</li>
                  <li>Right to data portability</li>
                  <li>Right to withdraw consent</li>
                </ul>
              </div>

              <div className="bg-white/10 backdrop-blur-sm rounded-2xl p-8">
                <h2 className="text-2xl font-light text-white mb-4">4. Contact for Privacy Questions</h2>
                <div className="text-white/80">
                  <p className="mb-4">For questions about privacy, contact us at:</p>
                  <div className="space-y-2">
                    <p>WebKraft Norge</p>
                    <p>Org.no: 933 294 684</p>
                    <p>Email: rekdalkontak@gmail.com</p>
                    <p>Phone: +47 959 16 988</p>
                    <p>Address: Hoemsbakken 1, 7023 Trondheim</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};