import { useState } from 'react';
import { Navbar } from '../components/Navbar';
import { Footer } from '../components/Footer';
import { Mail, MapPin, Phone, Send } from 'lucide-react';
import { Toaster, toast } from 'react-hot-toast';
import emailjs from '@emailjs/browser';

emailjs.init('N_oQkKZto_fwQHuLe');

export const ContactPage = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const response = await emailjs.send(
        'service_habazmq',
        'template_jv0dw7p',
        {
          from_name: formData.name,
          from_email: formData.email,
          message: formData.message,
          to_name: 'WebKraft Norge',
        }
      );

      if (response.status === 200) {
        toast.success('Message sent! We\'ll contact you soon.');
        setFormData({ name: '', email: '', message: '' });
      }
    } catch (error) {
      console.error('Form submission error:', error);
      toast.error('Sorry, something went wrong. Please try again later.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-rose-500 via-purple-600 to-blue-700">
      <Navbar />
      <Toaster position="top-right" />
      <main className="pt-32 pb-20">
        <div className="container mx-auto px-6">
          <div className="max-w-4xl mx-auto">
            <div className="text-center mb-16">
              <div className="inline-block px-4 py-2 bg-white/10 backdrop-blur-sm rounded-full text-white/90 text-sm mb-6">
                Contact Us
              </div>
              <h1 className="text-6xl font-light text-white mb-6">
                Let's Talk
              </h1>
              <p className="text-lg text-white/80">
                Tell us about your needs, and we'll help you find the best solution
              </p>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
              <div className="bg-white/10 backdrop-blur-sm rounded-2xl p-8">
                <form onSubmit={handleSubmit} className="space-y-6">
                  <div>
                    <label className="block text-sm text-white/80 mb-2">Name</label>
                    <input
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                      className="w-full px-4 py-2 bg-white/10 text-white rounded-lg focus:ring-2 focus:ring-white/20 focus:outline-none"
                    />
                  </div>
                  <div>
                    <label className="block text-sm text-white/80 mb-2">Email</label>
                    <input
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                      className="w-full px-4 py-2 bg-white/10 text-white rounded-lg focus:ring-2 focus:ring-white/20 focus:outline-none"
                    />
                  </div>
                  <div>
                    <label className="block text-sm text-white/80 mb-2">Message</label>
                    <textarea
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      required
                      rows={4}
                      className="w-full px-4 py-2 bg-white/10 text-white rounded-lg focus:ring-2 focus:ring-white/20 focus:outline-none"
                    />
                  </div>
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="w-full inline-flex items-center justify-center gap-2 bg-white/10 text-white px-6 py-3 rounded-full hover:bg-white/20 transition-all disabled:opacity-50"
                    onClick={() => window.gtagSendEvent && window.gtagSendEvent('')}
                  >
                    {isSubmitting ? 'Sending...' : 'Send Message'}
                    <Send className="h-5 w-5" />
                  </button>
                </form>
              </div>

              <div className="space-y-8">
                <div className="bg-white/10 backdrop-blur-sm rounded-2xl p-8">
                  <h3 className="text-xl font-light text-white mb-6">Contact Information</h3>
                  <div className="space-y-4">
                    <div className="flex items-center gap-3 text-white/80">
                      <Mail className="h-5 w-5" />
                      <span>rekdalkontak@gmail.com</span>
                    </div>
                    <div className="flex items-center gap-3 text-white/80">
                      <Phone className="h-5 w-5" />
                      <span>+47 959 16 988</span>
                    </div>
                    <div className="flex items-center gap-3 text-white/80">
                      <MapPin className="h-5 w-5" />
                      <span>Hoemsbakken 1, 7023 Trondheim</span>
                    </div>
                  </div>
                </div>

                <div className="bg-white/10 backdrop-blur-sm rounded-2xl p-8">
                  <h3 className="text-xl font-light text-white mb-4">Opening Hours</h3>
                  <div className="space-y-2 text-white/80">
                    <p>Monday - Friday: 09:00 - 16:00</p>
                    <p>Saturday - Sunday: Closed</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};