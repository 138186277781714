import { Navbar } from '../components/Navbar';
import { Footer } from '../components/Footer';
import { Check, ArrowRight, Globe, ShoppingCart, Calendar, MessageCircle, BarChart3, Smartphone, Bot, Zap } from 'lucide-react';
import { Link } from 'react-router-dom';

const productPackages = [
  {
    icon: Globe,
    title: 'Business Website',
    price: '20$',
    description: 'Professional website tailored to your brand',
    features: [
      'Responsive design for all devices',
      'SEO optimization',
      'Content management system',
      'Contact forms',
      'Google Analytics integration',
      '1 month free maintenance'
    ]
  },
  {
    icon: ShoppingCart,
    title: 'E-Commerce Solution',
    price: '50$',
    description: 'Complete online store with payment processing',
    features: [
      'Everything in Business Website +',
      'Product catalog management',
      'Secure payment processing',
      'Inventory management',
      'Order tracking system',
      'Customer accounts'
    ]
  },
  {
    icon: Calendar,
    title: 'Booking System',
    price: '20$',
    description: 'Streamline appointment scheduling',
    features: [
      'Online booking calendar',
      'Automated confirmations',
      'SMS/Email reminders',
      'Staff scheduling',
      'Payment integration',
      'Customer management'
    ]
  }
];

const aiSolutions = [
  {
    icon: MessageCircle,
    title: 'AI Customer Support',
    price: '30$',
    description: 'Intelligent chatbot for 24/7 customer service',
    features: [
      'Custom trained on your business',
      'Handles common questions',
      'Seamless handoff to human agents',
      'Multi-language support',
      'Analytics dashboard',
      'Regular updates and improvements'
    ]
  },
  {
    icon: BarChart3,
    title: 'Business Analytics',
    price: '40$',
    description: 'Data-driven insights for better decisions',
    features: [
      'Customer behavior analysis',
      'Sales performance tracking',
      'Trend identification',
      'Automated reporting',
      'Predictive analytics',
      'Custom dashboards'
    ]
  },
  {
    icon: Smartphone,
    title: 'Mobile App',
    price: '40$',
    description: 'Custom mobile application for your business',
    features: [
      'iOS and Android compatibility',
      'Brand-consistent design',
      'Push notifications',
      'User accounts',
      'Integration with your systems',
      'App Store submission'
    ]
  }
];

const customSolutions = [
  {
    icon: Bot,
    title: 'Custom AI Integration',
    description: 'Tailored AI solutions for specific business needs',
    features: [
      'Process automation',
      'Document analysis',
      'Predictive modeling',
      'Natural language processing',
      'Computer vision applications',
      'Custom algorithms'
    ]
  },
  {
    icon: Zap,
    title: 'Digital Transformation',
    description: 'Complete digital strategy and implementation',
    features: [
      'Business process analysis',
      'Technology stack modernization',
      'Legacy system integration',
      'Staff training',
      'Ongoing support',
      'Scalable architecture'
    ]
  }
];

export const PricingPage = () => {
  return (
    <div className="min-h-screen bg-gradient-to-br from-rose-500 via-purple-600 to-blue-700">
      <Navbar />
      <main className="pt-24 md:pt-32 pb-12 md:pb-20">
        <div className="container mx-auto px-4 md:px-6">
          <div className="text-center mb-8 md:mb-16">
            <div className="inline-block px-4 py-2 bg-white/10 backdrop-blur-sm rounded-full text-white/90 text-sm mb-4 md:mb-6">
              Solutions & Pricing
            </div>
            <h1 className="text-4xl md:text-6xl font-light text-white mb-4 md:mb-6">
              Digital Solutions for Your Business
            </h1>
            <p className="text-base md:text-lg text-white/80 max-w-3xl mx-auto px-4">
              From establishing your online presence to implementing smart AI solutions, 
              we offer comprehensive digital services tailored to your specific needs.
            </p>
          </div>

          {/* Web Solutions Section */}
          <div className="mb-16">
            <h2 className="text-2xl md:text-3xl font-light text-white text-center mb-8">Web Solutions</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-8 max-w-6xl mx-auto">
              {productPackages.map((plan, index) => {
                const Icon = plan.icon;
                return (
                  <div 
                    key={index} 
                    className="bg-white/10 backdrop-blur-sm rounded-2xl p-6 md:p-8 border border-white/10 shadow-[0_8px_32px_rgba(0,0,0,0.2)] hover:shadow-[0_8px_32px_rgba(255,255,255,0.1)] transition-all duration-300"
                  >
                    <div className="flex items-center gap-3 mb-4">
                      <div className="p-2 bg-white/5 rounded-lg">
                        <Icon className="h-5 w-5 md:h-6 md:w-6 text-white" />
                      </div>
                      <h3 className="text-lg md:text-xl font-light text-white">{plan.title}</h3>
                    </div>
                    <p className="text-white/80 text-sm mb-4">{plan.description}</p>
                    <div className="mb-4 md:mb-6">
                      <span className="text-2xl md:text-3xl font-light text-white">{plan.price}</span>
                      <span className="text-white/60 ml-1">/mo</span>
                    </div>
                    <ul className="space-y-2 md:space-y-3 mb-6 md:mb-8">
                      {plan.features.map((feature, i) => (
                        <li key={i} className="flex items-start gap-2 md:gap-3 text-sm md:text-base text-white/80">
                          <Check className="h-5 w-5 flex-shrink-0 mt-0.5" />
                          <span>{feature}</span>
                        </li>
                      ))}
                    </ul>
                    <Link
                      to="/contact"
                      className="w-full inline-flex items-center justify-center gap-2 bg-white/10 text-white px-4 md:px-6 py-2.5 md:py-3 rounded-full hover:bg-white/20 transition-all border border-white/10 shadow-[0_4px_12px_rgba(0,0,0,0.1)] text-sm md:text-base"
                      onClick={() => window.gtagSendEvent && window.gtagSendEvent('')}
                    >
                      Get Started
                      <ArrowRight className="h-4 w-4 md:h-5 md:w-5" />
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>

          {/* AI Solutions Section */}
          <div className="mb-16">
            <h2 className="text-2xl md:text-3xl font-light text-white text-center mb-8">AI & Advanced Solutions</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-8 max-w-6xl mx-auto">
              {aiSolutions.map((plan, index) => {
                const Icon = plan.icon;
                return (
                  <div 
                    key={index} 
                    className="bg-white/10 backdrop-blur-sm rounded-2xl p-6 md:p-8 border border-white/10 shadow-[0_8px_32px_rgba(0,0,0,0.2)] hover:shadow-[0_8px_32px_rgba(255,255,255,0.1)] transition-all duration-300"
                  >
                    <div className="flex items-center gap-3 mb-4">
                      <div className="p-2 bg-white/5 rounded-lg">
                        <Icon className="h-5 w-5 md:h-6 md:w-6 text-white" />
                      </div>
                      <h3 className="text-lg md:text-xl font-light text-white">{plan.title}</h3>
                    </div>
                    <p className="text-white/80 text-sm mb-4">{plan.description}</p>
                    <div className="mb-4 md:mb-6">
                      <span className="text-2xl md:text-3xl font-light text-white">{plan.price}</span>
                      <span className="text-white/60 ml-1">/mo</span>
                    </div>
                    <ul className="space-y-2 md:space-y-3 mb-6 md:mb-8">
                      {plan.features.map((feature, i) => (
                        <li key={i} className="flex items-start gap-2 md:gap-3 text-sm md:text-base text-white/80">
                          <Check className="h-5 w-5 flex-shrink-0 mt-0.5" />
                          <span>{feature}</span>
                        </li>
                      ))}
                    </ul>
                    <Link
                      to="/contact"
                      className="w-full inline-flex items-center justify-center gap-2 bg-white/10 text-white px-4 md:px-6 py-2.5 md:py-3 rounded-full hover:bg-white/20 transition-all border border-white/10 shadow-[0_4px_12px_rgba(0,0,0,0.1)] text-sm md:text-base"
                      onClick={() => window.gtagSendEvent && window.gtagSendEvent('')}
                    >
                      Get Started
                      <ArrowRight className="h-4 w-4 md:h-5 md:w-5" />
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>

          {/* Custom Enterprise Solutions */}
          <div className="max-w-6xl mx-auto mb-16">
            <h2 className="text-2xl md:text-3xl font-light text-white text-center mb-8">Enterprise Solutions</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {customSolutions.map((solution, index) => {
                const Icon = solution.icon;
                return (
                  <div 
                    key={index} 
                    className="bg-white/10 backdrop-blur-sm rounded-2xl p-6 md:p-8 border border-white/10 shadow-[0_8px_32px_rgba(0,0,0,0.2)] hover:shadow-[0_8px_32px_rgba(255,255,255,0.1)] transition-all duration-300"
                  >
                    <div className="flex items-center gap-3 mb-4">
                      <div className="p-2 bg-white/5 rounded-lg">
                        <Icon className="h-5 w-5 md:h-6 md:w-6 text-white" />
                      </div>
                      <h3 className="text-lg md:text-xl font-light text-white">{solution.title}</h3>
                    </div>
                    <p className="text-white/80 text-sm mb-4">{solution.description}</p>
                    <div className="mb-4 md:mb-6">
                      <span className="text-xl md:text-2xl font-light text-white">Custom Pricing</span>
                    </div>
                    <ul className="space-y-2 md:space-y-3 mb-6 md:mb-8">
                      {solution.features.map((feature, i) => (
                        <li key={i} className="flex items-start gap-2 md:gap-3 text-sm md:text-base text-white/80">
                          <Check className="h-5 w-5 flex-shrink-0 mt-0.5" />
                          <span>{feature}</span>
                        </li>
                      ))}
                    </ul>
                    <Link
                      to="/contact"
                      className="w-full inline-flex items-center justify-center gap-2 bg-white/10 text-white px-4 md:px-6 py-2.5 md:py-3 rounded-full hover:bg-white/20 transition-all border border-white/10 shadow-[0_4px_12px_rgba(0,0,0,0.1)] text-sm md:text-base"
                      onClick={() => window.gtagSendEvent && window.gtagSendEvent('')}
                    >
                      Request Consultation
                      <ArrowRight className="h-4 w-4 md:h-5 md:w-5" />
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>

          {/* Benefits Section */}
          <div className="max-w-4xl mx-auto bg-white/10 backdrop-blur-sm rounded-2xl p-6 md:p-8 border border-white/10 shadow-[0_8px_32px_rgba(0,0,0,0.2)] mb-8 md:mb-16">
            <h2 className="text-xl md:text-2xl font-light text-white mb-6 text-center">All Solutions Include</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-12">
              <div>
                <ul className="space-y-3 md:space-y-4">
                  <li className="flex items-start gap-2 md:gap-3">
                    <Check className="h-5 w-5 md:h-6 md:w-6 text-white/80 flex-shrink-0 mt-1" />
                    <p className="text-sm md:text-base text-white/80">1 month free trial with no obligations</p>
                  </li>
                  <li className="flex items-start gap-2 md:gap-3">
                    <Check className="h-5 w-5 md:h-6 md:w-6 text-white/80 flex-shrink-0 mt-1" />
                    <p className="text-sm md:text-base text-white/80">Dedicated support team</p>
                  </li>
                  <li className="flex items-start gap-2 md:gap-3">
                    <Check className="h-5 w-5 md:h-6 md:w-6 text-white/80 flex-shrink-0 mt-1" />
                    <p className="text-sm md:text-base text-white/80">Regular updates and improvements</p>
                  </li>
                </ul>
              </div>
              <div>
                <ul className="space-y-3 md:space-y-4">
                  <li className="flex items-start gap-2 md:gap-3">
                    <Check className="h-5 w-5 md:h-6 md:w-6 text-white/80 flex-shrink-0 mt-1" />
                    <p className="text-sm md:text-base text-white/80">Customization to match your brand</p>
                  </li>
                  <li className="flex items-start gap-2 md:gap-3">
                    <Check className="h-5 w-5 md:h-6 md:w-6 text-white/80 flex-shrink-0 mt-1" />
                    <p className="text-sm md:text-base text-white/80">Analytics and performance reporting</p>
                  </li>
                  <li className="flex items-start gap-2 md:gap-3">
                    <Check className="h-5 w-5 md:h-6 md:w-6 text-white/80 flex-shrink-0 mt-1" />
                    <p className="text-sm md:text-base text-white/80">Secure and reliable hosting</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/* Call to Action */}
          <div className="max-w-3xl mx-auto text-center px-4">
            <h2 className="text-xl md:text-2xl font-light text-white mb-4 md:mb-6">Not Sure What You Need?</h2>
            <p className="text-sm md:text-base text-white/80 mb-6 md:mb-8">
              Our team can help you identify the right solutions for your business. 
              Schedule a free consultation to discuss your specific needs and goals.
            </p>
            <Link
              to="/contact"
              className="w-full sm:w-auto inline-flex items-center justify-center gap-2 bg-white/10 backdrop-blur-sm text-white px-6 md:px-8 py-2.5 md:py-3 rounded-full hover:bg-white/20 transition-all border border-white/10 shadow-[0_8px_32px_rgba(0,0,0,0.2)] hover:shadow-[0_8px_32px_rgba(255,255,255,0.1)] text-sm md:text-base"
              onClick={() => window.gtagSendEvent && window.gtagSendEvent('')}
            >
              Schedule Free Consultation
              <ArrowRight className="h-4 w-4 md:h-5 md:w-5" />
            </Link>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};