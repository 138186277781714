import { Brain, Facebook, Instagram, Linkedin, Mail } from 'lucide-react';
import { Link } from 'react-router-dom';

export const Footer = () => {
  return (
    <footer className="bg-black/20 backdrop-blur-sm text-white py-8 md:py-12">
      <div className="container mx-auto px-4 md:px-6">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8 md:gap-12">
          <div>
            <div className="flex items-center gap-2 mb-4">
              <Brain className="h-6 w-6 md:h-8 md:w-8 text-white/80" />
              <span className="text-lg md:text-xl font-light">WebKraft</span>
            </div>
            <p className="text-sm md:text-base text-white/60">
              We help businesses establish a powerful digital presence and integrate smart technology solutions for growth and efficiency.
            </p>
          </div>

          <div>
            <h3 className="text-base md:text-lg font-light mb-4">Our Services</h3>
            <ul className="space-y-2 text-sm md:text-base">
              <li>
                <a href="#services" className="text-white/60 hover:text-white transition-colors">
                  Web Development
                </a>
              </li>
              <li>
                <a href="#services" className="text-white/60 hover:text-white transition-colors">
                  Business Systems
                </a>
              </li>
              <li>
                <a href="#services" className="text-white/60 hover:text-white transition-colors">
                  Digital Strategy
                </a>
              </li>
              <li>
                <a href="#services" className="text-white/60 hover:text-white transition-colors">
                  AI Integration
                </a>
              </li>
            </ul>
          </div>

          <div>
            <h3 className="text-base md:text-lg font-light mb-4">Company</h3>
            <ul className="space-y-2 text-sm md:text-base">
              <li>
                <Link to="/about" className="text-white/60 hover:text-white transition-colors">
                  About Us
                </Link>
              </li>
              <li>
                <Link to="/contact" className="text-white/60 hover:text-white transition-colors">
                  Contact
                </Link>
              </li>
              <li>
                <Link to="/privacy" className="text-white/60 hover:text-white transition-colors">
                  Privacy
                </Link>
              </li>
              <li>
                <Link to="/terms" className="text-white/60 hover:text-white transition-colors">
                  Terms
                </Link>
              </li>
            </ul>
          </div>

          <div>
            <h3 className="text-base md:text-lg font-light mb-4">Contact Us</h3>
            <div className="space-y-3 text-sm md:text-base">
              <p className="text-white/60">
                Hoemsbakken 1<br />
                7023 Trondheim
              </p>
              <a href="mailto:rekdalkontak@gmail.com" className="text-white/60 hover:text-white transition-colors flex items-center gap-2">
                <Mail className="h-4 w-4 md:h-5 md:w-5" />
                rekdalkontak@gmail.com
              </a>
              <div className="flex gap-4">
                <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className="text-white/60 hover:text-white transition-colors">
                  <Facebook className="h-5 w-5 md:h-6 md:w-6" />
                </a>
                <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" className="text-white/60 hover:text-white transition-colors">
                  <Instagram className="h-5 w-5 md:h-6 md:w-6" />
                </a>
                <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer" className="text-white/60 hover:text-white transition-colors">
                  <Linkedin className="h-5 w-5 md:h-6 md:w-6" />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="border-t border-white/10 mt-8 md:mt-12 pt-6 md:pt-8">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 text-center md:text-left text-sm">
            <p className="text-white/60">
              © {new Date().getFullYear()} WebKraft. All rights reserved.
            </p>
            <div className="flex flex-wrap justify-center md:justify-end gap-4">
              <Link to="/privacy" className="text-white/60 hover:text-white transition-colors">
                Privacy Policy
              </Link>
              <Link to="/terms" className="text-white/60 hover:text-white transition-colors">
                Terms of Use
              </Link>
              <Link to="/cookies" className="text-white/60 hover:text-white transition-colors">
                Cookies
              </Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};