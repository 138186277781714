import { Navbar } from '../components/Navbar';
import { Footer } from '../components/Footer';

export const TermsPage = () => {
  return (
    <div className="min-h-screen bg-gradient-to-br from-rose-500 via-purple-600 to-blue-700">
      <Navbar />
      <main className="pt-32 pb-20">
        <div className="container mx-auto px-6">
          <div className="max-w-3xl mx-auto">
            <div className="text-center mb-16">
              <div className="inline-block px-4 py-2 bg-white/10 backdrop-blur-sm rounded-full text-white/90 text-sm mb-6">
                Terms
              </div>
              <h1 className="text-6xl font-light text-white mb-6">
                Terms of Use
              </h1>
              <p className="text-lg text-white/80">
                Our terms for using services and AI solutions
              </p>
            </div>
            
            <div className="space-y-8">
              <div className="bg-white/10 backdrop-blur-sm rounded-2xl p-8">
                <h2 className="text-2xl font-light text-white mb-4">1. AI Services</h2>
                <p className="text-white/80 mb-4">
                  WebKraft AI offers the following services:
                </p>
                <ul className="list-disc pl-6 text-white/80 space-y-2">
                  <li>AI-driven chatbots</li>
                  <li>Automatic document analysis</li>
                  <li>Predictive analysis</li>
                  <li>Process automation</li>
                  <li>AI integration</li>
                </ul>
              </div>

              <div className="bg-white/10 backdrop-blur-sm rounded-2xl p-8">
                <h2 className="text-2xl font-light text-white mb-4">2. Pricing and Payment</h2>
                <ul className="list-disc pl-6 text-white/80 space-y-2">
                  <li>Custom pricing models</li>
                  <li>1 month free trial period</li>
                  <li>Flexible payment plans</li>
                  <li>Transparent pricing</li>
                </ul>
              </div>

              <div className="bg-white/10 backdrop-blur-sm rounded-2xl p-8">
                <h2 className="text-2xl font-light text-white mb-4">3. Support and Maintenance</h2>
                <ul className="list-disc pl-6 text-white/80 space-y-2">
                  <li>24/7 system monitoring</li>
                  <li>Technical support on weekdays</li>
                  <li>Monthly reports</li>
                  <li>Continuous optimization</li>
                </ul>
              </div>

              <div className="bg-white/10 backdrop-blur-sm rounded-2xl p-8">
                <h2 className="text-2xl font-light text-white mb-4">4. Contact Information</h2>
                <div className="text-white/80">
                  <p className="mb-4">For questions about our terms, contact:</p>
                  <div className="space-y-2">
                    <p>WebKraft AI</p>
                    <p>Org.no: 933 294 684</p>
                    <p>Email: rekdalkontak@gmail.com</p>
                    <p>Phone: +47 959 16 988</p>
                    <p>Address: Hoemsbakken 1, 7023 Trondheim</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};