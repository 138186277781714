import { Navbar } from '../components/Navbar';
import { Footer } from '../components/Footer';
import { Globe, Sparkles, Zap, BarChart3, Shield, Clock } from 'lucide-react';
import { Link } from 'react-router-dom';

const benefits = [
  {
    icon: Globe,
    title: 'Enhanced Online Presence',
    description: 'Stand out with a professional website and digital strategy that reaches your target audience.',
  },
  {
    icon: BarChart3,
    title: 'Increased Efficiency',
    description: 'Streamline operations with custom business systems and process automation.',
  },
  {
    icon: Sparkles,
    title: 'Better Customer Experience',
    description: 'Engage customers with intuitive interfaces and personalized interactions.',
  },
  {
    icon: Zap,
    title: 'Faster Growth',
    description: 'Accelerate business growth with data-driven insights and strategic digital marketing.',
  },
  {
    icon: Shield,
    title: 'Competitive Advantage',
    description: 'Stay ahead with innovative solutions that differentiate your business.',
  },
  {
    icon: Clock,
    title: 'Save Time and Resources',
    description: 'Free up valuable time by automating repetitive tasks and streamlining workflows.',
  },
];

export const WhyPage = () => {
  return (
    <div className="min-h-screen bg-gradient-to-br from-rose-500 via-purple-600 to-blue-700">
      <Navbar />
      <main className="pt-32 pb-20">
        <div className="container mx-auto px-6">
          <div className="max-w-4xl mx-auto text-center mb-16">
            <div className="inline-block px-4 py-2 bg-white/10 backdrop-blur-sm rounded-full text-white/90 text-sm mb-6">
              Digital Transformation
            </div>
            <h1 className="text-6xl font-light text-white mb-6">
              Why Go Digital?
            </h1>
            <p className="text-lg text-white/80">
              In today's competitive landscape, a strong digital presence is essential. 
              See how our solutions can transform your business with the right technology.
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {benefits.map((benefit, index) => {
              const Icon = benefit.icon;
              return (
                <div
                  key={index}
                  className="p-6 bg-white/10 backdrop-blur-sm rounded-2xl border border-white/10 shadow-[0_8px_32px_rgba(0,0,0,0.2)] hover:shadow-[0_8px_32px_rgba(255,255,255,0.1)] transition-all duration-300"
                >
                  <div className="flex items-center gap-4 mb-4">
                    <div className="p-3 bg-white/5 rounded-lg group-hover:bg-white/10 transition-colors">
                      <Icon className="h-6 w-6 text-white" />
                    </div>
                    <h3 className="text-xl font-light text-white">{benefit.title}</h3>
                  </div>
                  <p className="text-white/80">{benefit.description}</p>
                </div>
              );
            })}
          </div>

          <div className="mt-20 max-w-3xl mx-auto">
            <div className="space-y-8">
              <div className="bg-white/10 backdrop-blur-sm p-6 rounded-2xl border border-white/10 shadow-[0_8px_32px_rgba(0,0,0,0.2)]">
                <h3 className="text-xl font-light text-white mb-4">Smart Digital Solutions</h3>
                <ul className="space-y-2 text-white/80">
                  <li className="flex items-center gap-2">
                    <div className="w-1.5 h-1.5 bg-white/50 rounded-full"></div>
                    Custom website development
                  </li>
                  <li className="flex items-center gap-2">
                    <div className="w-1.5 h-1.5 bg-white/50 rounded-full"></div>
                    E-commerce platforms
                  </li>
                  <li className="flex items-center gap-2">
                    <div className="w-1.5 h-1.5 bg-white/50 rounded-full"></div>
                    Business process automation
                  </li>
                </ul>
              </div>
              
              <div className="bg-white/10 backdrop-blur-sm p-6 rounded-2xl border border-white/10 shadow-[0_8px_32px_rgba(0,0,0,0.2)]">
                <h3 className="text-xl font-light text-white mb-4">AI-Enhanced Features</h3>
                <ul className="space-y-2 text-white/80">
                  <li className="flex items-center gap-2">
                    <div className="w-1.5 h-1.5 bg-white/50 rounded-full"></div>
                    Intelligent customer service chatbots
                  </li>
                  <li className="flex items-center gap-2">
                    <div className="w-1.5 h-1.5 bg-white/50 rounded-full"></div>
                    Data analysis and insights
                  </li>
                  <li className="flex items-center gap-2">
                    <div className="w-1.5 h-1.5 bg-white/50 rounded-full"></div>
                    Personalized user experiences
                  </li>
                </ul>
              </div>

              <div className="bg-white/10 backdrop-blur-sm p-6 rounded-2xl border border-white/10 shadow-[0_8px_32px_rgba(0,0,0,0.2)]">
                <h3 className="text-xl font-light text-white mb-4">Business Growth Tools</h3>
                <ul className="space-y-2 text-white/80">
                  <li className="flex items-center gap-2">
                    <div className="w-1.5 h-1.5 bg-white/50 rounded-full"></div>
                    Digital marketing integration
                  </li>
                  <li className="flex items-center gap-2">
                    <div className="w-1.5 h-1.5 bg-white/50 rounded-full"></div>
                    Analytics and reporting
                  </li>
                  <li className="flex items-center gap-2">
                    <div className="w-1.5 h-1.5 bg-white/50 rounded-full"></div>
                    Customer relationship management
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="mt-12 text-center">
            <Link
              to="/contact"
              className="btn-primary"
            >
              Start Your Digital Journey
            </Link>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};